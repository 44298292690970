.navbar {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-dropdown {
  background-color: transparent !important;;
  color: white;
  appearance: none; /* Remove default browser styles */
  
  border-radius: 8px;
  border: none;
  outline: none;
  width: 10rem;
  font-size: 1rem;
}


.custom-dropdown option {
    background-color: transparent;
  color: black;
}

.center-section {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  transition: transform 0.2s ease-in-out;
}

.avatar:hover {
  transform: scale(1.05); /* Hover effect */
}

.icon {
  color: #fff;
  font-size: 0.875rem;
}

