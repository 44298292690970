.gradient{
    background: linear-gradient(to right,#9900FF, #E5E1E7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mainText{
  background: linear-gradient(90deg, #9900ff 74%, #e5e1e7 100%);
color: transparent;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
}