.gradientText{
    background: linear-gradient(90deg, #9900ff 41%, #e5e1e7 100%);
color: transparent;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
}

.gradient {
  background-image: url('../assets/image.png'); /* Adjust the path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white; /* Ensure the text is visible */
  transition: background-color 0.3s ease; /* Smooth transition */
}

