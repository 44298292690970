.markdown-editor {
  display: flex;
  font-family: "Arial", sans-serif;
}

.markdown-preview {
  padding: 20px;
  overflow-y: auto;
}

.markdown-preview h1,
.markdown-preview h2,
.markdown-preview h3 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.markdown-preview a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-preview a:hover {
  text-decoration: underline;
}

.markdown-preview table {
  width: 100%;
  border-collapse: collapse;
}

.markdown-preview table th,
.markdown-preview table td {
  padding: 10px;
  border: 1px solid #ddd;
}
